import React from 'react';
import PropTypes from 'prop-types';
import { Form, Select } from 'navoica-frontend-ui';

const AuthnCustomValidationFormGroup = (props) => {
  const {
    onBlur, onChange, onClick, onFocus, isFocusedField,
  } = props;

  // handler code that need to be invoked via input
  const onClickHandler = (e, clickCb) => {
    if (clickCb) {
      clickCb(e);
    }
  };
  const onBlurHandler = (e, blurCb) => {
    if (blurCb) {
      blurCb(e);
    }
  };
  const onChangeHandler = (e, changeCb) => {
    if (changeCb) {
      changeCb(e);
    }
  };
  const onFocusHandler = (e, focusCb) => {
    if (focusCb) {
      focusCb(e);
    }
  };
  const onOptionalHandler = (e, clickCb) => { clickCb(e); };

  const showLabel = () => {
    let className;

    return (
      <Form.Label htmlFor={props.for} className={className} isRequired={props.isRequired}>
        {props.label}
        {props.isRequired && <span className="sr-only">{props.textRequired}</span>}
      </Form.Label>
    );
  };
  const showOptional = () => {
    const additionalField = props.optionalFieldCheckbox ? (
      <p role="presentation" id="additionalFields" className="mb-1 small" onClick={(e) => onOptionalHandler(e, onClick)}>
        {props.checkboxMessage}
      </p>
    ) : <span />;
    return additionalField;
  };

  const isRequiredString = props.isRequired ? 'true' : 'false';

  const inputProps = {
    name: props.name,
    id: props.for,
    className: props.inputFieldStyle,
    'aria-invalid': props.ariaInvalid,
    'aria-required': isRequiredString,
    'aria-labelledby': `${props.name }label`,
  };

  inputProps.onChange = (e) => onChangeHandler(e, onChange);
  inputProps.onClick = (e) => onClickHandler(e, onClick);
  inputProps.onBlur = (e) => onBlurHandler(e, onBlur);
  inputProps.onFocus = (e) => onFocusHandler(e, onFocus);

  if (props.type === 'select') {
    inputProps.options = props.selectOptions;
    inputProps.className = props.value === '' ? `${props.inputFieldStyle} text-muted` : props.inputFieldStyle;

    if (props.selected !== '') {
      inputProps.defaultValue = props.selected;
    } else {
      inputProps.value = props.value;
    }
  } else {
    inputProps.value = props.value;
  }

  if (props.name === 'password' || props.name.includes('new-password')) {
    inputProps.hasIcon = props.hasIcon;

    if (inputProps.hasIcon) {
      inputProps.iconPosition = 'right';
      inputProps.screenReaderTextIcon = props.screenReaderTextIcon;
    } else { inputProps.type = props.type; }
  }

  if (props.name === 'confirmPassword') {
    inputProps.type = props.type;
  }

  if (props.type === 'checkbox') {
    inputProps.checked = props.isChecked;
  }

  const validationGroupProps = {
    controlId: props.type === 'checkbox' ? undefined : props.for,
  };
  if (!props.optionalFieldCheckbox) {
    validationGroupProps.isInvalid = props.invalid;
  } else {
    validationGroupProps.className = props.optionalFieldCheckbox ? 'custom-control pt-10 mb-0' : '';
  }
  if (props.className) {
    validationGroupProps.className = props.className;
  }

  const renderField = () => {
    switch (props.type) {
      case 'checkbox':
        return (
          <Form.Checkbox
            {...inputProps}
            isValid={!props.isChecked}
            isInvalid={props.isChecked}
            required
            isRequired
          >{props.label}
          </Form.Checkbox>
        );
      case 'select':
        return (
          <Select
            aria-label={props.label}
            as="select"
            {...inputProps}
            isValid={!props.invalid}
            isInvalid={props.invalid}
            autoComplete={props.autoComplete}
            spellCheck={props.spellCheck}
            defaultValue={props.selected}
            isRequired={props.isRequired}
          />
        );
      default:
        return (
          <Form.Control
            {...inputProps}
            isValid={!props.invalid}
            isInvalid={props.invalid}
            autoComplete={props.autoComplete}
            spellCheck={props.spellCheck}
            required
          />
        );
    }
  };

  const feedbackProps = {};

  if (isFocusedField === props.name && props.invalidMessage !== '') {
    feedbackProps['aria-live'] = 'assertive';
  } else if (isFocusedField === props.name && props.invalidMessage === '' && props.type === 'checkbox') {
    feedbackProps.id = '';
    inputProps['aria-labelledby'] = '';
  }

  if (props.hasAlert) {
    feedbackProps.role = 'alert';
  }

  return (
    <Form.Group
      {...validationGroupProps}
    >
      {(props.type !== 'checkbox') && showLabel()}
      {renderField()}
      {(!props.invalid && props.helpText) && <Form.Control.Feedback type="default" aria-atomic="true" aria-live="polite" controlClassName={props.for}>{props.helpText}</Form.Control.Feedback>}
      {<Form.Control.Feedback style={{ display: props.invalid ? 'block' : 'none' }} {...feedbackProps} id={`${props.name }label`} type="invalid" controlClassName={props.for} mt={props.type === 'checkbox' ? '0' : '0.25rem'}>{props.invalidMessage}</Form.Control.Feedback>}
      {showOptional()}
    </Form.Group>
  );
};

AuthnCustomValidationFormGroup.defaultProps = {
  name: '',
  for: '',
  label: '',
  optionalFieldCheckbox: false,
  type: '',
  value: '',
  invalid: false,
  ariaInvalid: false,
  invalidMessage: '',
  inputFieldStyle: '',
  helpText: '',
  className: '',
  onClick: null,
  onBlur: null,
  onChange: null,
  onFocus: null,
  isChecked: false,
  checkboxMessage: '',
  selectOptions: null,
  autoComplete: undefined,
  spellCheck: undefined,
  size: 'sm',
  hasIcon: false,
  screenReaderTextIcon: undefined,
  isFocusedField: undefined,
  hasAlert: undefined,
};

AuthnCustomValidationFormGroup.propTypes = {
  name: PropTypes.string,
  for: PropTypes.string,
  label: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
  ]),
  type: PropTypes.string,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.bool,
  ]),
  invalid: PropTypes.bool,
  ariaInvalid: PropTypes.bool,
  invalidMessage: PropTypes.string,
  helpText: PropTypes.string,
  className: PropTypes.string,
  inputFieldStyle: PropTypes.string,
  isChecked: PropTypes.bool,
  optionalFieldCheckbox: PropTypes.bool,
  onClick: PropTypes.func,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  onFocus: PropTypes.func,
  checkboxMessage: PropTypes.string,
  selectOptions: PropTypes.arrayOf(PropTypes.shape({
    key: PropTypes.string,
    value: PropTypes.string,
  })),
  autoComplete: PropTypes.string,
  spellCheck: PropTypes.string,
  isRequired: PropTypes.bool,
  size: PropTypes.oneOf([
    'sm',
    'md',
    'lg',
  ]),
  selected: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.bool,
  ]),
  defaultValue: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.bool,
  ]),
  textRequired: PropTypes.string,
  hasIcon: PropTypes.bool,
  screenReaderTextIcon: PropTypes.oneOfType(
    [
      PropTypes.string,
      PropTypes.element,
      PropTypes.arrayOf([PropTypes.string]),
    ],
  ),
  isFocusedField: PropTypes.string,
  hasAlert: PropTypes.bool,
};

export default AuthnCustomValidationFormGroup;
