import React from 'react';

import camelCase from 'lodash.camelcase';
import { connect } from 'react-redux';
import Skeleton from 'react-loading-skeleton';
import { Helmet } from 'react-helmet';
import PropTypes from 'prop-types';

import { getConfig } from '@edx/frontend-platform';
import { sendPageEvent, sendTrackEvent } from '@edx/frontend-platform/analytics';
import {
  injectIntl, intlShape, getCountryList, getLocale, FormattedMessage,
} from '@edx/frontend-platform/i18n';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { Form } from '@edx/paragon';
import {
  Hyperlink, Text, FormFrame,
} from 'navoica-frontend-ui';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { registerNewUser, fetchRealtimeValidations } from './data/actions';
import { registrationRequestSelector } from './data/selectors';
import messages from './messages';
import OptionalFields from './OptionalFields';
import RegistrationFailure from './RegistrationFailure';

import {
  RedirectLogistration, SocialAuthProviders, ThirdPartyAuthAlert, RenderInstitutionButton,
  InstitutionLogistration, AuthnValidationFormGroup,
} from '../common-components';
import ContactHelpText from '../common-components/ContactHelpText';
import { getThirdPartyAuthContext } from '../common-components/data/actions';
import { thirdPartyAuthContextSelector } from '../common-components/data/selectors';
import EnterpriseSSO from '../common-components/EnterpriseSSO';
import {
  DEFAULT_STATE, PENDING_STATE, REGISTER_PAGE, VALID_EMAIL_REGEX,
  VALID_NAME_REGEX, VALID_USERNAME_REGEX, VALID_PASSWORD_REGEX, INVALID_VALUE_WITH_WHITE_SPACE,
} from '../data/constants';
import {
  getTpaProvider, getTpaHint, getAllPossibleQueryParam, setSurveyCookie,
} from '../data/utils';

import {
  StatefulButton,
} from './styled';

class RegistrationPage extends React.Component {
  constructor(props, context) {
    super(props, context);

    sendPageEvent('login_and_registration', 'register');
    this.intl = props.intl;
    this.queryParams = getAllPossibleQueryParam();
    this.tpaHint = getTpaHint();

    this.state = {
      email: '',
      confirmEmail: '',
      name: '',
      username: '',
      password: '',
      confirmPassword: '',
      country: getLocale().toUpperCase() || null,
      gender: '',
      yearOfBirth: '',
      terms_of_service: false,
      honor_code: false,
      goals: '',
      levelOfEducation: '',
      enableOptionalField: true,
      validationAlertMessages: {
        name: [{ user_message: '' }],
        username: [{ user_message: '' }],
        email: [{ user_message: '' }],
        confirmEmail: [{ user_message: '' }],
        password: [{ user_message: '' }],
        confirmPassword: [{ user_message: '' }],
        country: [{ user_message: '' }],
        terms_of_service: [{ user_message: '' }],
        honor_code: [{ user_message: '' }],
      },
      errors: {
        email: '',
        confirmEmail: '',
        name: '',
        username: '',
        password: '',
        confirmPassword: '',
        country: '',
        terms_of_service: '',
        honor_code: '',
      },
      institutionLogin: false,
      formValid: false,
      startTime: Date.now(),
      updateFieldErrors: false,
      updateAlertErrors: false,
      registrationErrorsUpdated: false,
      isFocusedField: '',
    };
  }

  componentDidMount() {
    const payload = { ...this.queryParams };

    if (this.tpaHint) {
      payload.tpa_hint = this.tpaHint;
    }
    this.props.getThirdPartyAuthContext(payload);
  }

  shouldComponentUpdate(nextProps) {
    if (nextProps.statusCode !== 403 && this.props.validations !== nextProps.validations) {
      const { errors } = this.state;
      const { fieldName } = this.state;
      const errorMsg = nextProps.validations.validation_decisions[fieldName];
      errors[fieldName] = errorMsg;
      this.setState({
        errors,
      });
      return false;
    }

    if (this.props.thirdPartyAuthContext.pipelineUserDetails !== nextProps.thirdPartyAuthContext.pipelineUserDetails) {
      this.setState({
        ...nextProps.thirdPartyAuthContext.pipelineUserDetails,
      });
      return false;
    }

    if (this.props.registrationError !== nextProps.registrationError) {
      this.setState({
        formValid: false,
        registrationErrorsUpdated: true,
      });
      return false;
    }

    if (this.state.registrationErrorsUpdated && this.props.registrationError === nextProps.registrationError) {
      this.setState({
        formValid: false,
        registrationErrorsUpdated: false,
      });
      return false;
    }

    return true;
  }

  onChangeHandler = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value }, () => {
      this.validateAllFields(name);
    });
  }

  getCountryOptions = () => {
    const { intl } = this.props;
    return [{
      value: '',
      label: intl.formatMessage(messages['registration.country.label']),
    }].concat(getCountryList(getLocale())
      .map(({ code, name }) => ({ value: code, label: name }))
      .sort((a, b) => a.label.localeCompare(b.label)));
  }

  getOptionalFields() {
    const values = {};
    const optionalFields = getConfig().REGISTRATION_OPTIONAL_FIELDS.split(',');
    optionalFields.forEach((key) => {
      values[camelCase(key)] = this.state[camelCase(key)];
    });

    return (
      <OptionalFields
        values={values}
        onChangeHandler={this.onChangeHandler}
      />
    );
  }

  handleInstitutionLogin = () => {
    this.setState(prevState => ({ institutionLogin: !prevState.institutionLogin }));
  }

  isFieldWithSpacesOnly = (value) => {
    const whiteSpaceRegex = new RegExp(INVALID_VALUE_WITH_WHITE_SPACE, 'i');
    return whiteSpaceRegex.test(value);
  }

  validateAllFields = (target, isOnBlur = false) => {
    const payload = {
      email: this.state.email,
      confirmEmail: this.state.confirmEmail,
      password: this.state.password,
      confirmPassword: this.state.confirmPassword,
      name: this.state.name,
      username: this.state.username,
      country: this.state.country,
      // levelOfEducation: this.state.levelOfEducation,
      // gender: this.state.gender,
      // yearOfBirth: this.state.yearOfBirth,
      terms_of_service: this.state.terms_of_service,
      honor_code: this.state.honor_code,
    };

    if (!this.state.formValid) {
      Object.keys(payload).forEach((key, index) => {
        const currentFieldIndex = Object.keys(payload).findIndex(el => el === target);
        this.checkNoFieldErrors(this.state.errors);
        if (!isOnBlur && (target !== key) && (index <= currentFieldIndex)) {
          this.validateInput(key, payload[key], payload, true);
        }
      });
    }

    switch (target) {
      case 'honor_code':
        this.validateInput('honor_code', payload.honor_code, payload, true);
        break;
      case 'terms_of_service':
        this.validateInput('terms_of_service', payload.terms_of_service, payload, true);
        break;
      default:
    }

    this.setState({
      updateFieldErrors: true,
      updateAlertErrors: false,
    });
  }

  handleSubmit = (e) => {
    e.preventDefault();
    const totalRegistrationTime = (Date.now() - this.state.startTime) / 1000;
    let payload = {
      name: this.state.name,
      username: this.state.username,
      email: this.state.email,
      confirmEmail: this.state.confirmEmail,
      country: this.state.country,
      honor_code: this.state.honor_code,
      terms_of_service: this.state.terms_of_service,
    };

    if (this.props.thirdPartyAuthContext.currentProvider) {
      payload.social_auth_provider = this.props.thirdPartyAuthContext.currentProvider;
    } else {
      payload.password = this.state.password;
      payload.confirmPassword = this.state.confirmPassword;
    }

    const postParams = getAllPossibleQueryParam();
    payload = { ...payload, ...postParams };

    let finalValidation = this.state.formValid;

    Object.keys(payload).forEach(key => {
      finalValidation = this.validateInput(key, payload[key], payload);
    });

    // Since optional fields are not validated we can add it to payload after required fields
    // have been validated. This will save us unwanted calls to validateInput()
    const optionalFields = getConfig().REGISTRATION_OPTIONAL_FIELDS.split(',');
    optionalFields.forEach((key) => {
      const stateKey = camelCase(key);
      if (this.state[stateKey]) {
        payload[key] = this.state[stateKey];
      }
    });
    if (finalValidation) {
      payload.totalRegistrationTime = totalRegistrationTime;
      this.props.registerNewUser(payload);
    }
  }

  handleOnBlur = (e) => {
    e.preventDefault();
    const payload = {
      email: this.state.email,
      confirmEmail: this.state.confirmEmail,
      password: this.state.password,
      confirmPassword: this.state.confirmPassword,
      name: this.state.name,
      username: this.state.username,
      country: this.state.country,
      terms_of_service: this.state.terms_of_service,
      honor_code: this.state.honor_code,
    };
    const { name, value } = e.target;

    this.setState({
      updateFieldErrors: true,
      updateAlertErrors: false,
      fieldName: e.target.name,
    }, () => {
      this.validateInput(name, value, payload, false);

      if (name === 'terms_of_service') {
        this.validateAllFields(name, true);
      } else if (name === 'honor_code') {
        this.validateAllFields(name, true);
      }
    });
  }

  handleOnChange = (e) => {
    const { name } = e.target;
    this.setState({
      [e.target.name]: (e.target.type === 'checkbox') ? e.target.checked : e.target.value,
      updateFieldErrors: false,
      updateAlertErrors: false,
    }, () => {
      this.validateAllFields(name);
    });
  }

  handleOnFocus = (e) => {
    e.preventDefault();

    if (e.target.type !== 'checkbox') {
      const { errors } = this.state;
      this.setState({ errors, isFocusedField: e.target.name });
    } else {
      this.setState({ isFocusedField: e.target.name });
    }
  }

  checkNoFieldErrors(validations) {
    const keyValidList = Object.entries(validations).map(([key]) => !validations[key]);
    return keyValidList.every((current) => current === true);
  }

  checkNoAlertErrors(validations) {
    const keyValidList = Object.entries(validations).map(([key]) => {
      const validation = validations[key][0];
      return !validation.user_message;
    });
    return keyValidList.every((current) => current === true);
  }

  handleOnOptional(e) {
    const optionalEnable = this.state.enableOptionalField;
    const targetValue = (e.target.id === 'additionalFields') ? !optionalEnable : e.target.checked;
    this.setState({
      enableOptionalField: targetValue,
      updateAlertErrors: false,
      updateFieldErrors: false,
    });
    sendTrackEvent('edx.bi.user.register.optional_fields_selected', {});
  }

  handleLoginLinkClickEvent() {
    sendTrackEvent('edx.bi.login_form.toggled', { category: 'user-engagement' });
  }

  validateInput(inputName, value, payload, updateAlertMessage = true) {
    const { errors } = this.state;
    const { intl, statusCode } = this.props;
    const emailRegex = new RegExp(VALID_EMAIL_REGEX, 'i');
    const nameRegex = new RegExp(VALID_NAME_REGEX, 'gu');
    const usernameRegex = new RegExp(VALID_USERNAME_REGEX, 'i');
    const passwordRegex = new RegExp(VALID_PASSWORD_REGEX, 'i');

    let {
      formValid,
      updateFieldErrors,
      updateAlertErrors,
    } = this.state;
    const {
      email,
      password,
    } = this.state;
    switch (inputName) {
      case 'email':
        if (value.length < 1 || this.isFieldWithSpacesOnly(value)) {
          errors.email = intl.formatMessage(messages['email.validation.message']);
        } else if (value.length <= 2) {
          errors.email = intl.formatMessage(messages['email.ratelimit.less.chars.validation.message']);
        } else if (!emailRegex.test(value)) {
          errors.email = intl.formatMessage(messages['email.ratelimit.incorrect.format.validation.message']);
        } else if (payload && statusCode !== 403) {
          this.props.fetchRealtimeValidations(payload);
        } else {
          errors.email = '';
        }
        break;
      case 'confirmEmail':
        if (value.length < 1 || this.isFieldWithSpacesOnly(value)) {
          errors.confirmEmail = intl.formatMessage(messages['email.confirm.empty.validation.message']);
        } else if (value !== email) {
          errors.confirmEmail = intl.formatMessage(messages['email.confirm.validation.message']);
        } else {
          errors.confirmEmail = '';
        }
        break;
      case 'name':
        if (value.length < 1 || this.isFieldWithSpacesOnly(value)) {
          errors.name = intl.formatMessage(messages['fullname.validation.message']);
        } else if (!nameRegex.test(value)) {
          errors.name = intl.formatMessage(messages['fullname.minlimit.validation.message']);
        } else {
          errors.name = '';
        }
        break;
      case 'username':
        if (value.length < 1 || this.isFieldWithSpacesOnly(value)) {
          errors.username = intl.formatMessage(messages['username.validation.message']);
        } else if (value.length <= 1 || value.length > 255) {
          errors.username = intl.formatMessage(messages['username.ratelimit.less.chars.message']);
        } else if ((value.length >= 2 && value.length <= 255) && (!usernameRegex.test(value))) {
          errors.username = intl.formatMessage(messages['username.format.validation.message']);
        } else if (payload && statusCode !== 403) {
          this.props.fetchRealtimeValidations(payload);
        } else {
          errors.username = '';
        }
        break;
      case 'password': {
        const passwordValue = `${value}`;
        if (passwordValue.length < 1 || this.isFieldWithSpacesOnly(passwordValue)) {
          errors.password = intl.formatMessage(messages['register.page.password.validation.message']);
        } else if (passwordValue.length < 8) {
          errors.password = intl.formatMessage(messages['email.ratelimit.password.validation.message']);
        } else if ((passwordValue.length >= 8) && (!passwordRegex.test(passwordValue))) {
          errors.password = intl.formatMessage(messages['password.validation.message']);
        } else if (payload && statusCode !== 403) {
          this.props.fetchRealtimeValidations(payload);
        } else {
          errors.password = '';
        }
        break;
      }
      case 'confirmPassword': {
        const passwordValue = `${password}`;
        const confirmPasswordValue = `${value}`;
        if (confirmPasswordValue.length < 1 || this.isFieldWithSpacesOnly(confirmPasswordValue)) {
          errors.confirmPassword = intl.formatMessage(messages['password.confirm.empty.validation.message']);
        } else if (confirmPasswordValue !== passwordValue) {
          errors.confirmPassword = intl.formatMessage(messages['password.confirm.validation.message']);
        } else {
          errors.confirmPassword = '';
        }
        break;
      }
      case 'country':
        if (!value) {
          errors.country = intl.formatMessage(messages['country.validation.message']);
        } else {
          errors.country = '';
        }
        break;
      case 'terms_of_service':
        if (value === false) {
          errors.terms_of_service = intl.formatMessage(messages['terms.service.validation.message']);
        } else {
          errors.terms_of_service = '';
        }
        break;
      case 'honor_code':
        if (value === false) {
          errors.honor_code = intl.formatMessage(messages['honor.code.validation.message']);
        } else {
          errors.honor_code = '';
        }
        break;
      default:
        break;
    }

    if (updateAlertMessage) {
      updateFieldErrors = true;
      updateAlertErrors = true;
      formValid = this.checkNoFieldErrors(errors);
    }

    this.setState({
      formValid,
      updateFieldErrors,
      updateAlertErrors,
      errors,
    });
    return formValid;
  }

  updateFieldErrors(registrationError) {
    const {
      errors,
    } = this.state;
    Object.entries(registrationError).map(([key]) => {
      if (registrationError[key]) {
        errors[key] = registrationError[key][0].user_message;
      }
      return errors;
    });
  }

  updateValidationAlertMessages() {
    const {
      errors,
      validationAlertMessages,
    } = this.state;
    Object.entries(errors).map(([key, value]) => {
      if (validationAlertMessages[key]) {
        validationAlertMessages[key][0].user_message = value;
      }
      return validationAlertMessages;
    });
  }

  renderErrors(username, email) {
    let errorsObject = null;
    let { registrationErrorsUpdated } = this.state;
    const {
      updateAlertErrors,
      updateFieldErrors,
      validationAlertMessages,
    } = this.state;
    const { registrationError, submitState } = this.props;
    if (registrationError && registrationErrorsUpdated) {
      if (updateFieldErrors && submitState !== PENDING_STATE) {
        this.updateFieldErrors(registrationError);
      }
      registrationErrorsUpdated = false;
      errorsObject = registrationError;
    } else {
      if (updateAlertErrors && submitState !== PENDING_STATE) {
        this.updateValidationAlertMessages();
      }
      errorsObject = !this.checkNoAlertErrors(validationAlertMessages) ? validationAlertMessages : {};
    }

    const userData = { username, email };
    return (
      <RegistrationFailure
        user={userData}
        errors={errorsObject}
        isSubmitted={updateAlertErrors}
        submitButtonState={submitState}
      />
    );
  }

  renderThirdPartyAuth(providers, secondaryProviders, currentProvider, thirdPartyAuthApiStatus, intl) {
    let thirdPartyComponent = null;
    if (((providers && providers.length) || (secondaryProviders && secondaryProviders.length)) && !currentProvider) {
      thirdPartyComponent = (
        <>
          <RenderInstitutionButton
            onSubmitHandler={this.handleInstitutionLogin}
            secondaryProviders={this.props.thirdPartyAuthContext.secondaryProviders}
            buttonTitle={intl.formatMessage(messages['register.institution.login.button'])}
          />
          <div className="row tpa-container">
            <SocialAuthProviders socialAuthProviders={providers} referrer={REGISTER_PAGE} />
          </div>
        </>
      );
    } else if (thirdPartyAuthApiStatus === PENDING_STATE) {
      thirdPartyComponent = <Skeleton height={36} count={2} />;
    }
    return thirdPartyComponent;
  }

  renderForm(currentProvider,
    providers,
    secondaryProviders,
    thirdPartyAuthApiStatus,
    finishAuthUrl,
    submitState,
    intl) {
    if (this.state.institutionLogin) {
      return (
        <InstitutionLogistration
          onSubmitHandler={this.handleInstitutionLogin}
          secondaryProviders={this.props.thirdPartyAuthContext.secondaryProviders}
          headingTitle={intl.formatMessage(messages['register.institution.login.page.title'])}
          buttonTitle={intl.formatMessage(messages['create.an.account'])}
        />
      );
    }

    if (this.props.registrationResult.success) {
      setSurveyCookie('register');
    }

    const localeLang = getLocale();
    const TEXT_REQUIRED = intl.formatMessage(messages['required.field']);

    return (
      <>
        <Helmet>
          <html lang={localeLang} amp />
          <title>{intl.formatMessage(messages['register.page.title'],
            { siteName: getConfig().SITE_NAME })}
          </title>
          <meta name="description" content={intl.formatMessage(messages['register.page.description'])} />
        </Helmet>
        <RedirectLogistration
          success={this.props.registrationResult.success}
          redirectUrl={this.props.registrationResult.redirectUrl}
          finishAuthUrl={finishAuthUrl}
        />
        <div className="d-flex justify-content-center authn-form__container" role="main">
          <div className="d-flex flex-column">
            <FormFrame>
              {currentProvider && (
                <ThirdPartyAuthAlert
                  currentProvider={currentProvider}
                  platformName={this.props.thirdPartyAuthContext.platformName}
                  referrer={REGISTER_PAGE}
                />
              )}
              <h1 className="authn-form__title text-left mb-3 h3">{intl.formatMessage(messages['create.a.new.account'])}</h1>
              <Text className="authn-form__paragraph">
                {intl.formatMessage(messages['you.will.get.access.to.free.courses'])}<br />
                <span className="text-semi-bold">{intl.formatMessage(messages['required.fields'])}</span>
              </Text>
              {this.renderErrors(this.state.username, this.state.email)}
              <Form className="form-group">
                <AuthnValidationFormGroup
                  label={intl.formatMessage(messages['register.page.email.label'])}
                  for="email"
                  name="email"
                  type="text"
                  className="data-hj-suppress"
                  invalid={this.state.errors.email !== ''}
                  ariaInvalid={this.state.errors.email !== ''}
                  invalidMessage={this.state.errors.email}
                  value={this.state.email}
                  onBlur={this.handleOnBlur}
                  onChange={this.handleOnChange}
                  onFocus={this.handleOnFocus}
                  inputFieldStyle="border-gray-600"
                  isRequired
                  spellCheck="false"
                  autoComplete="email"
                  textRequired={TEXT_REQUIRED}
                  isFocusedField={this.state.isFocusedField}
                />
                <AuthnValidationFormGroup
                  label={intl.formatMessage(messages['register.page.email.confirm.label'])}
                  for="confirmEmail"
                  name="confirmEmail"
                  type="text"
                  className="data-hj-suppress"
                  invalid={this.state.errors.confirmEmail !== ''}
                  ariaInvalid={this.state.errors.confirmEmail !== ''}
                  invalidMessage={this.state.errors.confirmEmail}
                  value={this.state.confirmEmail}
                  onBlur={this.handleOnBlur}
                  onChange={this.handleOnChange}
                  onFocus={this.handleOnFocus}
                  inputFieldStyle="border-gray-600"
                  isRequired
                  spellCheck="false"
                  autoComplete="email"
                  textRequired={TEXT_REQUIRED}
                  isFocusedField={this.state.isFocusedField}
                />
                {!currentProvider && (
                  <AuthnValidationFormGroup
                    label={intl.formatMessage(messages['password.label'])}
                    for="password"
                    name="password"
                    invalid={this.state.errors.password !== ''}
                    ariaInvalid={this.state.errors.password !== ''}
                    invalidMessage={this.state.errors.password}
                    value={this.state.password}
                    onBlur={this.handleOnBlur}
                    onChange={this.handleOnChange}
                    onFocus={this.handleOnFocus}
                    helpText={intl.formatMessage(messages['helptext.password'])}
                    inputFieldStyle="border-gray-600"
                    isRequired
                    spellCheck="false"
                    autoComplete="new-password"
                    textRequired={TEXT_REQUIRED}
                    hasIcon
                    screenReaderTextIcon={[
                      intl.formatMessage(messages['show.password.message']),
                      intl.formatMessage(messages['hide.password.message']),
                      this.state.password !== ''
                        ? intl.formatMessage(messages['password.visible.message'])
                        : `${intl.formatMessage(messages['password.visible.message'])} ${intl.formatMessage(messages['empty.value'])}`,
                      intl.formatMessage(messages['password.hidden.message']),
                    ]}
                    isFocusedField={this.state.isFocusedField}
                  />
                )}
                {!currentProvider && (
                  <AuthnValidationFormGroup
                    label={intl.formatMessage(messages['password.confirm.label'])}
                    for="confirmPassword"
                    name="confirmPassword"
                    type="password"
                    invalid={this.state.errors.confirmPassword !== ''}
                    ariaInvalid={this.state.errors.confirmPassword !== ''}
                    invalidMessage={this.state.errors.confirmPassword}
                    value={this.state.confirmPassword}
                    onBlur={this.handleOnBlur}
                    onChange={this.handleOnChange}
                    onFocus={this.handleOnFocus}
                    inputFieldStyle="border-gray-600"
                    isRequired
                    spellCheck="false"
                    autoComplete="off"
                    textRequired={TEXT_REQUIRED}
                    isFocusedField={this.state.isFocusedField}
                  />
                )}
                <AuthnValidationFormGroup
                  label={intl.formatMessage(messages['fullname.label'])}
                  for="name"
                  name="name"
                  type="text"
                  invalid={this.state.errors.name !== ''}
                  ariaInvalid={this.state.errors.name !== ''}
                  invalidMessage={this.state.errors.name}
                  value={this.state.name}
                  onBlur={this.handleOnBlur}
                  onChange={this.handleOnChange}
                  onFocus={this.handleOnFocus}
                  helpText={intl.formatMessage(messages['helptext.name'])}
                  inputFieldStyle="border-gray-600"
                  isRequired
                  autocomplete="name"
                  textRequired={TEXT_REQUIRED}
                  isFocusedField={this.state.isFocusedField}
                />
                <AuthnValidationFormGroup
                  label={intl.formatMessage(messages['username.label'])}
                  for="username"
                  name="username"
                  type="text"
                  className="data-hj-suppress"
                  invalid={this.state.errors.username !== ''}
                  ariaInvalid={this.state.errors.username !== ''}
                  invalidMessage={this.state.errors.username}
                  value={this.state.username}
                  onBlur={this.handleOnBlur}
                  onChange={this.handleOnChange}
                  onFocus={this.handleOnFocus}
                  helpText={intl.formatMessage(messages['helptext.username'])}
                  inputFieldStyle="border-gray-600"
                  isRequired
                  autocomplete="username"
                  textRequired={TEXT_REQUIRED}
                  isFocusedField={this.state.isFocusedField}
                />
                <AuthnValidationFormGroup
                  label={intl.formatMessage(messages['registration.country.label'])}
                  for="country"
                  name="country"
                  type="select"
                  key="country"
                  invalid={this.state.errors.country !== null && this.state.errors.country !== ''}
                  ariaInvalid={this.state.errors.country !== null && this.state.errors.country !== ''}
                  invalidMessage={this.state.errors.country}
                  className="data-hj-suppress"
                  value={this.state.country}
                  onBlur={this.handleOnBlur}
                  onChange={this.handleOnChange}
                  onFocus={this.handleOnFocus}
                  selectOptions={this.getCountryOptions()}
                  selected={getLocale().toUpperCase()}
                  inputFieldStyle="border-gray-600 custom-select-size"
                  isRequired={false}
                  textRequired={TEXT_REQUIRED}
                />
                { this.state.enableOptionalField ? this.getOptionalFields() : null }
                <AuthnValidationFormGroup
                  label={(
                    <FormattedMessage
                      id="agree.terms_of_service"
                      tagName="span"
                      defaultMessage="I agree to the {termsOfService}"
                      description="Text that appears on registration form stating terms of service"
                      values={{
                        platformName: getConfig().SITE_NAME,
                        termsOfService: (
                          <Hyperlink className="ml-1" destination={`${getConfig().LMS_BASE_URL}/tos`} target="_blank" showLaunchIcon={false}>
                            {intl.formatMessage(messages['terms.service'])}
                          </Hyperlink>
                        ),
                      }}
                    />
                  )}
                  for="terms_of_service"
                  name="terms_of_service"
                  type="checkbox"
                  size="sm"
                  className="mb-030"
                  invalid={this.state.terms_of_service === false}
                  value={this.state.terms_of_service}
                  onBlur={this.handleOnBlur}
                  onChange={this.handleOnChange}
                  onFocus={this.handleOnFocus}
                  isChecked={this.state.terms_of_service}
                  invalidMessage={this.state.errors.terms_of_service}
                  isRequired
                  textRequired={TEXT_REQUIRED}
                  isFocusedField={this.state.isFocusedField}
                />
                <AuthnValidationFormGroup
                  label={(
                    <FormattedMessage
                      id="agree.honor_code"
                      tagName="span"
                      defaultMessage="I agree to the {honorCode}"
                      description="Text that appears on registration form stating honor code"
                      values={{
                        platformName: getConfig().SITE_NAME,
                        honorCode: (
                          <Hyperlink className="ml-1" destination={getConfig().TOS_AND_HONOR_CODE} target="_blank" showLaunchIcon={false}>
                            {intl.formatMessage(messages['honor.code'])}
                          </Hyperlink>
                        ),
                      }}
                    />
                  )}
                  for="honor_code"
                  name="honor_code"
                  type="checkbox"
                  size="sm"
                  className="mb-030"
                  invalid={this.state.honor_code === false}
                  value={this.state.honor_code}
                  onBlur={this.handleOnBlur}
                  onChange={this.handleOnChange}
                  onFocus={this.handleOnFocus}
                  isChecked={this.state.honor_code}
                  invalidMessage={this.state.errors.honor_code}
                  isRequired
                  textRequired={TEXT_REQUIRED}
                  isFocusedField={this.state.isFocusedField}
                />
                <StatefulButton
                  block
                  type="submit"
                  variant="brand"
                  size="sm"
                  state={submitState}
                  labels={{
                    default: intl.formatMessage(messages['create.account.button']),
                  }}
                  icons={{ pending: <FontAwesomeIcon icon={faSpinner} spin /> }}
                  onClick={this.handleSubmit}
                  onMouseDown={(e) => e.preventDefault()}
                />
                {((providers && providers.length)
                || (secondaryProviders && secondaryProviders.length)
                || thirdPartyAuthApiStatus === PENDING_STATE)
                  && !currentProvider ? (
                    <div className="d-block mb-4 mt-4">
                      <hr className="mt-0 border-gray-200" />
                      <span className="d-block mb-4 text-left">
                        {intl.formatMessage(messages['create.an.account.using'])}
                      </span>
                    </div>
                  ) : null}
                {this.renderThirdPartyAuth(providers,
                  secondaryProviders,
                  currentProvider,
                  thirdPartyAuthApiStatus,
                  intl)}
              </Form>
              <ContactHelpText intl={intl} message={intl.formatMessage(messages['cant.register'])} id="register-contact-help" />
            </FormFrame>
          </div>
        </div>
      </>
    );
  }

  render() {
    const { intl, submitState, thirdPartyAuthApiStatus } = this.props;
    const {
      currentProvider, finishAuthUrl, providers, secondaryProviders,
    } = this.props.thirdPartyAuthContext;

    if (this.tpaHint) {
      if (thirdPartyAuthApiStatus === PENDING_STATE) {
        return <Skeleton height={36} />;
      }
      const { provider, skipHintedLogin } = getTpaProvider(this.tpaHint, providers, secondaryProviders);
      if (skipHintedLogin) {
        window.location.href = getConfig().LMS_BASE_URL + provider.registerUrl;
        return null;
      }
      return provider ? (<EnterpriseSSO provider={provider} intl={intl} />)
        : this.renderForm(
          currentProvider,
          providers,
          secondaryProviders,
          thirdPartyAuthApiStatus,
          finishAuthUrl,
          submitState,
          intl,
        );
    }
    return this.renderForm(
      currentProvider,
      providers,
      secondaryProviders,
      thirdPartyAuthApiStatus,
      finishAuthUrl,
      submitState,
      intl,
    );
  }
}

RegistrationPage.defaultProps = {
  registrationResult: null,
  registerNewUser: null,
  registrationError: null,
  submitState: DEFAULT_STATE,
  thirdPartyAuthApiStatus: 'pending',
  thirdPartyAuthContext: {
    currentProvider: null,
    finishAuthUrl: null,
    providers: [],
    secondaryProviders: [],
    pipelineUserDetails: null,
  },
  validations: null,
  statusCode: null,
};

RegistrationPage.propTypes = {
  intl: intlShape.isRequired,
  getThirdPartyAuthContext: PropTypes.func.isRequired,
  registerNewUser: PropTypes.func,
  registrationResult: PropTypes.shape({
    redirectUrl: PropTypes.string,
    success: PropTypes.bool,
  }),
  registrationError: PropTypes.shape({
    email: PropTypes.array,
    confirmEmail: PropTypes.array,
    password: PropTypes.array,
    confirmPassword: PropTypes.array,
    name: PropTypes.array,
    username: PropTypes.array,
    country: PropTypes.array,
    terms_of_service: PropTypes.array,
    honor_code: PropTypes.array,
  }),
  submitState: PropTypes.string,
  thirdPartyAuthApiStatus: PropTypes.string,
  thirdPartyAuthContext: PropTypes.shape({
    currentProvider: PropTypes.string,
    platformName: PropTypes.string,
    providers: PropTypes.array,
    secondaryProviders: PropTypes.array,
    finishAuthUrl: PropTypes.string,
    pipelineUserDetails: PropTypes.shape({
      email: PropTypes.string,
      fullname: PropTypes.string,
      firstName: PropTypes.string,
      lastName: PropTypes.string,
      username: PropTypes.string,
    }),
  }),
  fetchRealtimeValidations: PropTypes.func.isRequired,
  validations: PropTypes.shape({
    validation_decisions: PropTypes.shape({
      email: PropTypes.string,
      confirmEmail: PropTypes.string,
      password: PropTypes.string,
      confirmPassword: PropTypes.string,
      name: PropTypes.string,
      username: PropTypes.string,
      country: PropTypes.string,
      levelOfEducation: PropTypes.string,
      gender: PropTypes.string,
      yearOfBirth: PropTypes.string,
      terms_of_service: PropTypes.bool,
      honor_code: PropTypes.bool,
    }),
  }),
  statusCode: PropTypes.number,
};

const mapStateToProps = state => {
  const registrationResult = registrationRequestSelector(state);
  const thirdPartyAuthContext = thirdPartyAuthContextSelector(state);
  return {
    registrationError: state.register.registrationError,
    submitState: state.register.submitState,
    thirdPartyAuthApiStatus: state.commonComponents.thirdPartyAuthApiStatus,
    registrationResult,
    thirdPartyAuthContext,
    validations: state.register.validations,
    statusCode: state.register.statusCode,
  };
};

export default connect(
  mapStateToProps,
  {
    getThirdPartyAuthContext,
    fetchRealtimeValidations,
    registerNewUser,
  },
)(injectIntl(RegistrationPage));
