import React from 'react';
import PropTypes from 'prop-types';
import { getConfig } from '@edx/frontend-platform';
import { injectIntl, intlShape } from '@edx/frontend-platform/i18n';
import { Hyperlink } from 'navoica-frontend-ui';
import messages from '../../login/messages';

import {
  Container,
} from './styled';

const ContactHelpText = (props) => {
  const { intl, message, id } = props;

  return (
    <Container id={id}>
      {message}{' '}{intl.formatMessage(messages['send.email'])}{' '}
      <span className="sr-only">{getConfig().SUPPORT_EMAIL}</span>
      <Hyperlink destination={`mailto:${getConfig().SUPPORT_EMAIL}`} aria-labelledby={id}>{getConfig().SUPPORT_EMAIL}.</Hyperlink>
    </Container>
  );
};

ContactHelpText.propTypes = {
  intl: intlShape.isRequired,
  message: PropTypes.string,
  id: PropTypes.string,
};

export default injectIntl(ContactHelpText);
