import styled from 'styled-components';
import { StandardModal } from 'navoica-frontend-ui';

const ConfirmationModal = styled(StandardModal)`
    max-width: 38.625rem;
`;

const ConfirmationContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;

    & img {
        margin: 0 35px 0 8px;

        @media screen and (max-width: 767px) {
            display: none;
        }
    }
`;

export {
  ConfirmationModal,
  ConfirmationContainer,
};
