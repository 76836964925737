import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import {
  injectIntl, intlShape,
} from '@edx/frontend-platform/i18n';
import { Alert } from 'navoica-frontend-ui';

import { FORBIDDEN_REQUEST, INTERNAL_SERVER_ERROR } from './data/constants';
import messages from './messages';
import { DEFAULT_STATE, PENDING_STATE } from '../data/constants';
import { windowScrollTo } from '../data/utils';

const RegistrationFailureMessage = (props) => {
  const errorMessage = props.errors;
  const { errorCode } = props.errors;
  const { username, email } = props.user || {};
  const userErrors = [];
  const refAlert = useRef(null);

  const setRefAlert = () => {
    if (refAlert && refAlert.current) {
      refAlert.current.focus();
    }
  };

  useEffect(() => {
    if (props.isSubmitted && props.submitButtonState !== PENDING_STATE) {
      windowScrollTo({ left: 0, top: 0, behavior: 'smooth' });
      setRefAlert();
    }
  });

  let serverError;
  let hasErrorValidation = false;

  const SERVER_ERROR_MSG = {
    EMAIL_EXIST: props.intl.formatMessage(messages['email.exist.validation.message'],
      { email }),
    USERNAME_EXIST: props.intl.formatMessage(messages['username.exist.validation.message'],
      { username }),
  };

  switch (errorCode) {
    case INTERNAL_SERVER_ERROR:
      serverError = (
        <li key={INTERNAL_SERVER_ERROR} className="text-left text-semi-bold">
          {props.intl.formatMessage(messages['registration.request.server.error'])}
        </li>
      );
     userErrors.push(serverError);
     break;
    case FORBIDDEN_REQUEST:
      userErrors.push(
        <li key={FORBIDDEN_REQUEST} className="text-left text-semi-bold">
          {props.intl.formatMessage(messages['register.rate.limit.reached.message'])}
        </li>,
      );
      break;
    default:
      Object.keys(errorMessage).forEach((key) => {
        if (key !== 'error_code') {
          const errors = errorMessage[key];
          const errorList = errors.map((error) => {
            if (error.user_message) {
              switch (error.user_message) {
                case SERVER_ERROR_MSG.EMAIL_EXIST:
                case SERVER_ERROR_MSG.USERNAME_EXIST:
                  /** Do not show 409 error code message in alert, is shown in invalid feedback */
                  hasErrorValidation = true;
                  break;
                default:
                  /** Show error field validation message in alert */
                  hasErrorValidation = true;
                  break;
              }
            } else { return null; }
            return false;
          });
          userErrors.push(errorList);
        }
      });

      if (hasErrorValidation) {
        /** Error validation message to show in alert */
        const errorValidation = (
          <li key="validation-error">
            <p className="text-semi-bold mb-0">{props.intl.formatMessage(messages['registration.request.failure.header'])}</p>
            <p className="mb-0">{props.intl.formatMessage(messages['registration.request.failure.description'])}</p>
          </li>
        );

        userErrors.push(errorValidation);
      }
      break;
  }

  return (
    !userErrors.length ? null : (
      <Alert id="validation-errors" variant="error" align="left" tabIndex={-1} alertRef={refAlert}>
        <ul className="m-0 p-0">{userErrors}</ul>
      </Alert>
    )
  );
};

RegistrationFailureMessage.defaultProps = {
  errors: '',
  submitButtonState: DEFAULT_STATE,
  isSubmitted: false,
};

RegistrationFailureMessage.propTypes = {
  errors: PropTypes.shape({
    email: PropTypes.array,
    username: PropTypes.array,
    errorCode: PropTypes.string,
  }),
  submitButtonState: PropTypes.string,
  isSubmitted: PropTypes.bool,
  intl: intlShape.isRequired,
  user: PropTypes.shape({
    email: PropTypes.string,
    username: PropTypes.string,
  }),
};

export default injectIntl(RegistrationFailureMessage);
