import styled from 'styled-components';
import {
  StatefulButton as Button,
  Text,
} from 'navoica-frontend-ui';

const StatefulButton = styled(Button)`
 &&& {
    margin-top: 2.25rem;
 }
`;

export {
  StatefulButton,
  Text,
};
