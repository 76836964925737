import styled from 'styled-components';

const Container = styled.div`
    margin: 28px 0 40px 0;
    width: 100%;
    display: flex;
    justify-content: space-between;
`;

export {
  Container,
};
