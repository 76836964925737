import styled from 'styled-components';
import { Hyperlink, Text as Paragraph } from 'navoica-frontend-ui';

const StyledConfirmAlert = styled.div`
    background-color: #ffffff;
    box-shadow: 0px 10px 30px rgba(224, 224, 224, 0.5);
    border: 1px solid rgba(222, 223, 223, 1);
    border-radius: 3px;
    padding: 3.75rem 6.25rem 3.75rem 6.25rem;
    display: flex;
    margin-bottom: 1.25rem;
    max-width: 77.75rem;
    margin: 0 auto;

    @media screen and (min-width: 768px) {
        flex-direction: row;
        justify-content: space-between;
        margin-top: 64px;
    }

    @media screen and (max-width: 767px) {
        padding: 1rem 1rem 1.5rem;
        flex-direction: column;
        margin-top: 32px;
    }
`;

const AlertTitle = styled.h2`
    font-family: 'Open Sans', sans-serif;
    font-size: 1.375rem;
    line-height: 30px;
    color: #374049;
    margin: 0 0 1.25rem 0;
`;

const Image = styled.img`
    width: 100%;
    height: auto;
    max-width: 7.144rem;
    min-height: 6.438rem;
    align-self: center;
`;

const AlertMessageContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 49.75rem;

    @media screen and (min-width: 960px) {
        margin-left: 6.25rem;
    }

    @media screen and (min-width: 768px)and (max-width: 959px) {
        margin-left: 2.188rem;
    }

    @media screen and (max-width: 767px) {
        margin-top: 1.875rem;
    }
`;

const Link = styled(Hyperlink)`  
    &&& { 
        font-size: 1rem;
    }
`;

const Text = styled(Paragraph)`
    &&& {
        line-height: 1.5rem;
    }
`;

export {
  StyledConfirmAlert,
  AlertTitle,
  AlertMessageContainer,
  Image,
  Link,
  Text,
};
