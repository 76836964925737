import styled from 'styled-components';
import {
  Text as Paragraph,
} from 'navoica-frontend-ui';

const Container = styled(Paragraph)`

&&& {
    margin-top: 26px;
    margin-bottom: 0;
}
`;

export {
  Container,
};
