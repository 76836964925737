import React from 'react';
import { Helmet } from 'react-helmet';
import PropTypes from 'prop-types';
import {
  updatePathWithQueryParams,
} from '../../../data/utils';
import {
  LOGIN_PAGE,
} from '../../../data/constants';
import SuccessIcon from '../../../assets/images/success-icon.svg';

import {
  AlertTitle,
  AlertMessageContainer,
  StyledConfirmAlert,
  Image,
  Text,
  Link,
} from './styled';

const ResetSuccess = ({
  message, linkText, seoTitle, lang,
}) => (
  <>
    <Helmet>
      <html lang={lang} amp />
      <title>{seoTitle}</title>
    </Helmet>
    <StyledConfirmAlert role="main">
      <AlertMessageContainer>
        <Image src={SuccessIcon} alt="" />
        <AlertTitle>{message}</AlertTitle>
      </AlertMessageContainer>
      <Text className="text-center my-0">
        <Link destination={updatePathWithQueryParams(LOGIN_PAGE)} href={LOGIN_PAGE}>
          {linkText}
        </Link>
      </Text>
    </StyledConfirmAlert>
  </>
);

ResetSuccess.propTypes = {
  message: PropTypes.string.isRequired,
  linkText: PropTypes.string.isRequired,
  seoTitle: PropTypes.string.isRequired,
  lang: PropTypes.string.isRequired,
};

export default ResetSuccess;
