import Cookies from 'universal-cookie';
import { getConfig } from '@edx/frontend-platform';

export function setSurveyCookie(surveyType) {
  const cookieName = getConfig().USER_SURVEY_COOKIE_NAME;
  if (cookieName) {
    const cookies = new Cookies();
    const signupTimestamp = (new Date()).getTime();
    // set expiry to exactly 24 hours from now
    const cookieExpiry = new Date(signupTimestamp + 1 * 864e5);
    const options = { domain: getConfig().COOKIE_DOMAIN, expires: cookieExpiry, path: '/' };
    cookies.set(cookieName, surveyType, options);
  }
}

export function getLocaleLang() {
  const cookies = new Cookies();
  let cookieLangPref = cookies.get(getConfig().LANGUAGE_PREFERENCE_COOKIE_NAME);

  if (cookieLangPref) {
    cookieLangPref = cookieLangPref.toLowerCase();
  }

  return cookieLangPref;
}
