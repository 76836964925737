import React from 'react';
import { Helmet } from 'react-helmet';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl, intlShape } from '@edx/frontend-platform/i18n';
import {
  updatePathWithQueryParams,
} from '../../../data/utils';
import {
  LOGIN_PAGE,
} from '../../../data/constants';
import messages from '../../messages';

import MailError from '../../../assets/images/mail-error.svg';

import {
  AlertTitle,
  AlertMessageContainer,
  StyledConfirmAlert,
  Image,
  Link,
  Text,
} from './styled';

const InvalidToken = ({
  linkText, seoTitle, intl, lang,
}) => (
  <>
    <Helmet>
      <html lang={lang} amp />
      <title>{seoTitle}</title>
    </Helmet>
    <StyledConfirmAlert role="main">
      <Image src={MailError} alt="" />
      <AlertMessageContainer>
        <AlertTitle>{intl.formatMessage(messages['reset.password.request.invalid.token.header'])}</AlertTitle>
        <Text color="#374049" size="lg">
          <FormattedMessage
            id="reset.password.request.invalid.token.description.message"
            defaultMessage="This password reset link is invalid. It may have been used already.
          To reset your password, go to the login page and select {forgotPassword}."
            description="Invalid password reset link help text message."
            values={{
              forgotPassword: <strong> &quot;{intl.formatMessage(messages['reset.password.request.forgot.password.text'])}&quot; </strong>,
            }}
          />
        </Text>
        <Text className="text-left my-0" size="lg">
          <Link destination={updatePathWithQueryParams(LOGIN_PAGE)} href={LOGIN_PAGE}>
            {linkText}
          </Link>
        </Text>
      </AlertMessageContainer>
    </StyledConfirmAlert>
  </>
);

InvalidToken.propTypes = {
  linkText: PropTypes.string.isRequired,
  seoTitle: PropTypes.string.isRequired,
  intl: intlShape.isRequired,
  lang: PropTypes.string.isRequired,
};

export default injectIntl(InvalidToken);
