import styled from 'styled-components';
import { Hyperlink, Text } from 'navoica-frontend-ui';

const StyledConfirmAlert = styled.div`
    background-color: #ffffff;
    box-shadow: 0px 10px 30px rgba(224, 224, 224, 0.5);
    border: 1px solid rgba(222, 223, 223, 1);
    border-radius: 3px;
    padding: 2.5rem 5.5rem;
    display: flex;
    margin-bottom: 1.25rem;
    max-width: 35.25rem;
    margin: 0 auto;
    margin-top: 64px;

    @media screen and (min-width: 768px) {
        flex-direction: column;
    }

    @media screen and (max-width: 767px) {
        padding: 1rem 1rem 1.5rem;
        flex-direction: column;
        margin-top: 32px;
    }
`;

const AlertTitle = styled.h2`
    font-family: 'Open Sans', sans-serif;
    font-size: 1.375rem;
    line-height: 1.875rem;
    color: #374049;
    
    &&& {
        margin: 0 0 1.5rem 0.783rem;
    }
`;

const Image = styled.img`
    width: 100%;
    height: auto;
    max-width: 1.718rem;
    min-height: 1.718rem;
    align-self: flex-start;
    transform: translateY(0.156rem);
`;

const AlertMessageContainer = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
    
    @media screen and (max-width: 767px) {
        margin-top: 1.875rem;
    }
`;

const Link = styled(Hyperlink)`
    font-size: 1rem;
`;

export {
  StyledConfirmAlert,
  AlertTitle,
  AlertMessageContainer,
  Image,
  Link,
  Text,
};
