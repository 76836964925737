import React from 'react';

import {
  VALID_PASSWORD_REGEX,
} from '../../../data/constants';
import messages from '../../../login/messages';

export default function LoginOverride(SomeComponent) {
  SomeComponent.prototype.validatePassword = function (password) {
    const { errors } = this.state;
    const passwordRegex = new RegExp(VALID_PASSWORD_REGEX, 'i');

    if (passwordRegex.test(password)) {
      errors.password = '';
    } else if (password.length > 0 && password.length < 8) {
      errors.password = this.props.intl.formatMessage(messages['password.validation.less.chars.message']);
    } else {
      errors.password = this.props.intl.formatMessage(messages['password.validation.message']);
    }

    this.setState({ errors });
    return errors.password;
  };
  return class extends React.Component {
    render() {
      return <SomeComponent {...this.props} />;
    }
  };
}
