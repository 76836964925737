import styled from 'styled-components';
import {
  StatefulButton as Button,
} from 'navoica-frontend-ui';

const StatefulButton = styled(Button)`
    margin-top: 2.5rem;
`;

export {
  StatefulButton,
};
