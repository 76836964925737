import React from 'react';
import PropTypes from 'prop-types';

import { injectIntl, intlShape } from '@edx/frontend-platform/i18n';

import { EDUCATION_LEVELS, GENDER_OPTIONS, YEAR_OF_BIRTH_OPTIONS } from './data/constants';
import messages from './messages';

import { AuthnValidationFormGroup } from '../common-components';

const OptionalFields = (props) => {
  const { intl, onChangeHandler, values } = props;

  const getOptions = () => ({
    yearOfBirthOptions: [{
      value: '',
      label: intl.formatMessage(messages['select.placeholder']),
    }].concat(YEAR_OF_BIRTH_OPTIONS),
    educationLevelOptions: EDUCATION_LEVELS.map(key => ({
      value: key,
      label: intl.formatMessage(messages[`registration.field.education.levels.${key || 'label'}`]),
    })),
    genderOptions: GENDER_OPTIONS.map(key => ({
      value: key,
      label: intl.formatMessage(messages[`registration.field.gender.options.${key || 'label'}`]),
    })),
  });

  return (
    <>
      <AuthnValidationFormGroup
        label={intl.formatMessage(messages['registration.field.education.label'])}
        for="levelOfEducation"
        name="levelOfEducation"
        type="select"
        as="select"
        key="levelOfEducation"
        value={values.levelOfEducation}
        className="mb-20 data-hj-suppress"
        onChange={onChangeHandler}
        selectOptions={getOptions().educationLevelOptions}
        inputFieldStyle="border-gray-600 custom-select-size"
        isRequired={false}
      />
      <AuthnValidationFormGroup
        label={intl.formatMessage(messages['registration.field.gender.label'])}
        for="gender"
        name="gender"
        type="select"
        key="gender"
        value={values.gender}
        className="col-6 pl-0 pr-075 mb-23 d-inline-block"
        onChange={onChangeHandler}
        selectOptions={getOptions().genderOptions}
        inputFieldStyle="border-gray-600 custom-select-size"
        isRequired={false}
      />
      <AuthnValidationFormGroup
        label={intl.formatMessage(messages['registration.year.of.birth.label'])}
        for="yearOfBirth"
        name="yearOfBirth"
        type="select"
        key="yearOfBirth"
        value={values.yearOfBirth}
        className="col-6 pr-0 pl-075 mb-23 d-inline-block"
        onChange={onChangeHandler}
        selectOptions={getOptions().yearOfBirthOptions}
        inputFieldStyle="border-gray-600 custom-select-size"
        isRequired={false}
      />
    </>
  );
};

OptionalFields.propTypes = {
  intl: intlShape.isRequired,
  onChangeHandler: PropTypes.func.isRequired,
  values: PropTypes.shape({
    gender: PropTypes.string,
    goals: PropTypes.string,
    levelOfEducation: PropTypes.string,
    yearOfBirth: PropTypes.string,
  }).isRequired,
};

export default injectIntl(OptionalFields);
