import React, { useState, useEffect, useCallback } from 'react';

import { Formik } from 'formik';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import { Redirect } from 'react-router-dom';

import { getConfig } from '@edx/frontend-platform';
import { sendPageEvent } from '@edx/frontend-platform/analytics';
import { injectIntl, intlShape } from '@edx/frontend-platform/i18n';
import {
  Form,
  Text,
  Alert,
} from 'navoica-frontend-ui';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';

import { forgotPassword, forgotPasswordResetStatus } from './data/actions';
import { forgotPasswordResultSelector } from './data/selectors';
import RequestInProgressAlert from './RequestInProgressAlert';

import messages from './messages';
import {
  AuthnValidationFormGroup,
} from '../common-components';
import ContactHelpText from '../common-components/ContactHelpText';
import APIFailureMessage from '../common-components/APIFailureMessage';
import { INTERNAL_SERVER_ERROR, LOGIN_PAGE, VALID_EMAIL_REGEX } from '../data/constants';
import LoginHelpLinks from '../login/LoginHelpLinks';
import { updatePathWithQueryParams, windowScrollTo } from '../data/utils';

import {
  StatefulButton,
} from './styled';

const ForgotPasswordPage = (props) => {
  const {
    intl, status, closeModal, isOpenModal,
  } = props;

  // const platformName = getConfig().SITE_NAME;
  const regex = new RegExp(VALID_EMAIL_REGEX, 'i');
  const [validationError, setValidationError] = useState('');

  const closeModalHandler = useCallback(() => {
    closeModal();
  });

  useEffect(() => {
    props.forgotPasswordResetStatus();
  }, []);

  useEffect(() => {
    let error = '';

    if (status === 'forbidden') {
      error = intl.formatMessage(messages['forgot.password.request.in.progress.message']);
      setValidationError(error);
    }

    if (status === 'complete') {
      setValidationError('');
    }
  }, [status]);

  useEffect(() => {
    if (isOpenModal === false) {
      setValidationError('');
    }
  }, [isOpenModal]);

  const getErrorMessage = (errors) => {
    const header = intl.formatMessage(messages['forgot.password.request.server.error']);
    if (errors.email) {
      return (
        <Alert variant="danger" align="left" tabIndex={-1}>
          <Alert.Heading>{header}</Alert.Heading>
          <ul className="my-0 p-0"><li>{errors.email}</li></ul>
        </Alert>
      );
    }

    if (status === INTERNAL_SERVER_ERROR) {
      return <APIFailureMessage header={header} errorCode={INTERNAL_SERVER_ERROR} />;
    }

    return status === 'forbidden' ? <RequestInProgressAlert /> : null;
  };

  const getValidationMessage = (email) => {
    let error = '';

    if (email === '') {
      error = intl.formatMessage(messages['forgot.password.empty.email.field.error']);
    } else if (!regex.test(email)) {
      error = intl.formatMessage(messages['forgot.password.page.invalid.email.message']);
    }

    setValidationError(error);
    return error;
  };

  sendPageEvent('login_and_registration', 'reset');

  return (
    <Formik
      initialValues={{ email: '' }}
      validateOnChange={false}
      validate={(values) => {
        const validationMessage = getValidationMessage(values.email);

        if (validationMessage !== '') {
          windowScrollTo({ left: 0, top: 0, behavior: 'smooth' });
          return { email: validationMessage };
        }

        return {};
      }}
      onSubmit={(values) => { props.forgotPassword(values.email); closeModalHandler(); }}
    >
      {({
        errors, handleSubmit, setFieldValue, values,
      }) => (
        <>
          <Helmet>
            <title>{intl.formatMessage(messages['forgot.password.page.title'],
              { siteName: getConfig().SITE_NAME })}
            </title>
          </Helmet>
          {status === 'complete' ? <Redirect to={updatePathWithQueryParams(LOGIN_PAGE)} /> : null}
          <div>
            <div role="main" aria-labelledby="forgot-password-description">

              {null && getErrorMessage(errors)}
              {null && (
                <h1 className="mt-3 h3">
                  {intl.formatMessage(messages['forgot.password.page.heading'])}
                </h1>
              )}
              <Text color="#212529" size="lg" className="mb-4" id="forgot-password-description">
                {intl.formatMessage(messages['forgot.password.page.instructions'])}
              </Text>
              <Form>
                <AuthnValidationFormGroup
                  label={intl.formatMessage(messages['forgot.password.page.email.field.label'])}
                  for="forgot-password-input"
                  name="email"
                  type="email"
                  invalid={validationError !== ''}
                  ariaInvalid={validationError !== ''}
                  invalidMessage={validationError}
                  value={values.email}
                  // onBlur={() => getValidationMessage(values.email)}
                  onChange={e => setFieldValue('email', e.target.value)}
                  // helpText={intl.formatMessage(messages['forgot.password.email.help.text'], { platformName })}
                  className="mb-0 w-100"
                  inputFieldStyle="border-gray-600"
                  hasAlert
                />
                {null && <LoginHelpLinks page="forgot-password" />}
                <StatefulButton
                  block
                  size="sm"
                  variant="brand"
                  type="submit"
                  state={status}
                  labels={{
                    default: intl.formatMessage(messages['forgot.password.page.submit.button']),
                  }}
                  icons={{ pending: <FontAwesomeIcon icon={faSpinner} spin /> }}
                  onClick={handleSubmit}
                  onMouseDown={(e) => e.preventDefault()}
                />
                <ContactHelpText intl={intl} message={intl.formatMessage(messages['changing.password'])} id="password-contact-help" />
              </Form>
            </div>
          </div>
        </>
      )}
    </Formik>
  );
};

ForgotPasswordPage.propTypes = {
  intl: intlShape.isRequired,
  forgotPassword: PropTypes.func.isRequired,
  forgotPasswordResetStatus: PropTypes.func.isRequired,
  status: PropTypes.string,
  closeModal: PropTypes.func,
  isOpenModal: PropTypes.bool,
};

ForgotPasswordPage.defaultProps = {
  status: null,
};

export default connect(
  forgotPasswordResultSelector,
  {
    forgotPassword,
    forgotPasswordResetStatus,
  },
)(injectIntl(ForgotPasswordPage));
