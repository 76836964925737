import styled from 'styled-components';
import {
  Alert as AlertActivation,
} from 'navoica-frontend-ui';

const Alert = styled(AlertActivation)`
    margin-bottom: 2rem;
    max-width: 35.25rem;
`;

export {
  Alert,
};
