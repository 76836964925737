import React from 'react';
import PropTypes from 'prop-types';

import { Text } from 'navoica-frontend-ui';
import MailSent from '../../../assets/images/mail-sent.svg';

import {
  AlertTitle,
  AlertMessageContainer,
  StyledConfirmAlert,
  Image,
} from './styled';

const AccountActivationConfirmAlert = ({ title, messages }) => {
  const FONT_COLOR_BLACK = '#374049';
  const FONT_COLOR_GREY = '#686970';
  const textColor = [
    FONT_COLOR_BLACK,
    FONT_COLOR_GREY,
  ];

  return (
    <StyledConfirmAlert>
      <Image src={MailSent} alt="" />
      <AlertMessageContainer>
        <AlertTitle>{title}</AlertTitle>
        {messages.length > 0 && messages.map((message, i) => <Text color={textColor[i]} size="lg">{message}</Text>)}
      </AlertMessageContainer>
    </StyledConfirmAlert>
  );
};

AccountActivationConfirmAlert.propTypes = {
  title: PropTypes.string.isRequired,
  messages: PropTypes.arrayOf(PropTypes.any).isRequired,
};

export default AccountActivationConfirmAlert;
