import React, { useEffect, useRef } from 'react';

import { FormattedMessage, injectIntl, intlShape } from '@edx/frontend-platform/i18n';
import { getConfig } from '@edx/frontend-platform';
import { Alert } from 'navoica-frontend-ui';
import PropTypes from 'prop-types';
import ForgotPasswordModal from '../forgot-password/customComponents/ForgotPasswordModal';

import processLink from '../data/utils';
import {
  ACCOUNT_LOCKED_OUT,
  FAILED_LOGIN_ATTEMPT,
  FORBIDDEN_REQUEST,
  INACTIVE_USER,
  INCORRECT_EMAIL_PASSWORD,
  INTERNAL_SERVER_ERROR,
  INVALID_FORM,
  NON_COMPLIANT_PASSWORD_EXCEPTION,
} from './data/constants';
import messages from './messages';

const LoginFailureMessage = (props) => {
  const {
    intl, email, isConfirmedSendEmail, isSubmited,
  } = props;
  const { context, errorCode, value } = props.loginError;

  const refAlert = useRef(null);

  const setRefAlert = () => {
    if (isSubmited && refAlert && refAlert.current) {
      refAlert.current.focus();
    }
  };

  let errorList;
  let link;
  let loginErrorContext;

  useEffect(() => {
    setRefAlert();
  }, [isSubmited]);

  if (typeof context === 'undefined') {
    loginErrorContext = {
      platformName: getConfig().PLATFORM_NAME,
      supportLink: getConfig.SUPPORT_LINK,
      lockedOutPeriod: '',
      remainingAttempts: '',
    };
  } else { loginErrorContext = { ...context }; }

  switch (errorCode) {
    case NON_COMPLIANT_PASSWORD_EXCEPTION: {
      errorList = (
        <ul className="my-0 p-0">
          <li key="password-non-compliance">
            <FormattedMessage
              id="non.compliant.password.error"
              defaultMessage="{passwordComplaintRequirements} {lineBreak}Your current password does not meet the new security
              requirements. We just sent a password-reset message to the email address associated with this account.
              Thank you for helping us keep your data safe."
              values={{
                passwordComplaintRequirements: <strong>{intl.formatMessage(messages['non.compliant.password.title'])}</strong>,
                lineBreak: <br />,
              }}
            />
          </li>
        </ul>
      );
      break;
    }
    case FORBIDDEN_REQUEST:
      errorList = (
        <ul className="my-0 p-0">
          <li key={FORBIDDEN_REQUEST} className="text-semi-bold">
            {intl.formatMessage(messages['login.rate.limit.reached.message'])}
          </li>
        </ul>
      );
      break;
    case INACTIVE_USER: {
      const supportLink = (
        <Alert.Link href={`mailto:${getConfig().SUPPORT_EMAIL}`}>
          {`${intl.formatMessage(messages['contact.support.link'], { platformName: getConfig().PLATFORM_NAME || loginErrorContext.platformName })} ${getConfig().SUPPORT_EMAIL}`}
        </Alert.Link>
      );
      errorList = (
        <ul className="my-0 p-0">
          <li key={INACTIVE_USER}>
            <FormattedMessage
              id="login.inactive.user.error"
              defaultMessage="In order to sign in, you need to activate your account.{lineBreak}
              {lineBreak}We just sent an activation link to {email}. If you do not receive an email,
              check your spam folders or {supportLink}."
              values={{
                lineBreak: <br />,
                email: <strong className="data-hj-suppress">{props.loginError.email}</strong>,
                supportLink,
              }}
            />
          </li>
        </ul>
      );
      break;
    }
    case INTERNAL_SERVER_ERROR:
      errorList = (
        <ul className="my-0 p-0">
          <li key={INTERNAL_SERVER_ERROR} className="text-semi-bold">
            {intl.formatMessage(messages['internal.server.error.message'])}
          </li>
        </ul>
      );
      break;
    case INVALID_FORM:
      /** errorList = (
        <>
          {context.email && <li key={`${INVALID_FORM}-email`}>{context.email}</li>}
          {context.password && <li key={`${INVALID_FORM}-password`}>{context.password}</li>}
        </>
      );*/
      errorList = (<span className="text-semi-bold">{intl.formatMessage(messages['login.failure.header.title'])}</span>);
      break;
    case FAILED_LOGIN_ATTEMPT: {
      const resetLink = (
        <ForgotPasswordModal
          isConfirmedSendEmail={isConfirmedSendEmail}
          email={email}
          btnText={intl.formatMessage(messages['login.failed.link.text'])}
        />
      );
      errorList = (
        <ul className="my-0 p-0">
          <li key={FAILED_LOGIN_ATTEMPT + 1}>
            {intl.formatMessage(messages['login.incorrect.credentials.error'])}
          </li>
          {loginErrorContext.remainingAttempts && (
            <li key={FAILED_LOGIN_ATTEMPT + 2}>
              {intl.formatMessage(messages['login.failed.attempt.error'], { remainingAttempts: loginErrorContext.remainingAttempts })}
            </li>
          )}
          <li key={FAILED_LOGIN_ATTEMPT + 3}>
            <FormattedMessage
              id="login.reset.password.message.with.link"
              defaultMessage="If you've forgotten your password, click {resetLink} to reset."
              description="Password reset user message with link"
              values={{ resetLink }}
            />
          </li>
        </ul>
      );
      break;
    }
    case ACCOUNT_LOCKED_OUT: {
      const resetLink = (
        <ForgotPasswordModal
          isConfirmedSendEmail={isConfirmedSendEmail}
          email={email}
          btnText={intl.formatMessage(messages['login.failed.link.text'])}
        />
      );

      const LOCKED_OUT_PERIOD = loginErrorContext.lockedOutPeriod || '30';
      errorList = (
        <ul className="my-0 p-0">
          {LOCKED_OUT_PERIOD && (
            <li key={ACCOUNT_LOCKED_OUT + 1}>
              {intl.formatMessage(messages['login.locked.out.error.message'], { lockedOutPeriod: LOCKED_OUT_PERIOD })}
            </li>
          )}
          <li key={FAILED_LOGIN_ATTEMPT + 2}>
            <FormattedMessage
              id="login.locked.reset.password.message.with.link"
              defaultMessage="To be on the safe side, you can reset your password {resetLink} before you try again."
              description="Password reset user message with link"
              values={{ resetLink }}
            />
          </li>
        </ul>
      );
      break;
    }
    case INCORRECT_EMAIL_PASSWORD:
      errorList = (
        <span className="text-semi-bold">{intl.formatMessage(messages['login.failure.header.title'])}</span>
      );
      break;
    default: {
      // TODO: use errorCode instead of processing error messages on frontend
      errorList = value.trim().split('\n');
      errorList = errorList.map((error) => {
        let matches;
        if (error.includes('a href')) {
          matches = processLink(error);
          const [beforeLink, href, linkText, afterLink] = matches;
          link = href;
          if (href.indexOf('/dashboard?tpa_hint') === 0) {
            link = `/login?next=${href}`;
          }
          return (
            <li key={error}>
              {beforeLink}
              <Alert.Link href={link}>{linkText}</Alert.Link>
              {afterLink}
            </li>
          );
        }
        return <li key={error}>{error}</li>;
      });

      errorList = (
        <ul className="my-0 p-0">
          {errorList}
        </ul>
      );
    }
  }

  return (
    <Alert id="login-failure-alert" variant="error" align="left" tabIndex={-1} alertRef={refAlert}>
      {errorList}
    </Alert>
  );
};

LoginFailureMessage.defaultProps = {
  loginError: {
    errorCode: null,
    value: '',
  },
};

LoginFailureMessage.propTypes = {
  email: PropTypes.string,
  isConfirmedSendEmail: PropTypes.bool,
  isSubmited: PropTypes.bool,
  loginError: PropTypes.shape({
    context: PropTypes.object,
    email: PropTypes.string,
    errorCode: PropTypes.string,
    value: PropTypes.string,
  }),
  intl: intlShape.isRequired,
};

export default injectIntl(LoginFailureMessage);
