import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { injectIntl, intlShape } from '@edx/frontend-platform/i18n';

import { useToggle } from '@edx/paragon';
import { Button, StandardModal } from 'navoica-frontend-ui';
import messages from '../../../login/messages';
import ForgotPasswordPage from '../../ForgotPasswordPage';
import ConfirmationForgotPasswordModal from '../ConfirmationForgotPasswordModal/ConfirmationForgotPasswordModal';

import {
  Container,
} from './styled';

const ForgotPasswordModal = (props) => {
  const {
    intl, isConfirmedSendEmail, email, btnText,
  } = props;
  const [isOpen, open, close] = useToggle(false);
  const [isOpenConfirmed, openConfirmed, closeConfirmed] = useToggle(false);

  useEffect(() => {
    if (isConfirmedSendEmail) {
      openConfirmed();
    } else {
      closeConfirmed();
    }
  }, [isConfirmedSendEmail]);

  return (
    <>
      <Container>
        <Button
          className="p-0"
          variant="link"
          onClick={open}
          data-toggle="pgn__modal"
          rel="leanModal"
          aria-haspopup="true"
        >
          {btnText || intl.formatMessage(messages['forgot.password.link'])}
        </Button>
      </Container>
      {isConfirmedSendEmail && !isOpen
        && (
          <ConfirmationForgotPasswordModal
            email={email}
            isOpen={isOpenConfirmed}
            open={openConfirmed}
            close={closeConfirmed}
          />
        )}
      {isOpen && (
        <StandardModal
          isOpen={isOpen}
          title={intl.formatMessage(messages['forgot.password.link'])}
          size="sm"
          onClose={close}
          closeLabel={intl.formatMessage(messages['forgot.password.close'])}
          hasCloseButton
        >
          <ForgotPasswordPage closeModal={close} isOpenModal={isOpen} />
        </StandardModal>
      )}
    </>
  );
};

ForgotPasswordModal.propTypes = {
  intl: intlShape.isRequired,
  isConfirmedSendEmail: PropTypes.bool,
  email: PropTypes.string,
  btnText: PropTypes.string,
};

export default injectIntl(ForgotPasswordModal);
