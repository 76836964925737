import React, { useState } from 'react';

import { FormattedMessage, injectIntl, intlShape } from '@edx/frontend-platform/i18n';
import PropTypes from 'prop-types';
import { getConfig } from '@edx/frontend-platform';
import { ACCOUNT_ACTIVATION_MESSAGE } from '../../data/constants';
import messages from '../../messages';

import AccountActivationConfirmAlert from '../AccountActivationConfirmAlert';

import {
  Alert,
} from './styled';

const AccountActivationMessage = (props) => {
  const { intl, messageType } = props;
  const variant = messageType === ACCOUNT_ACTIVATION_MESSAGE.ERROR ? 'danger' : messageType;

  let activationMessage;
  let activationMessageSecondary;
  let heading;

  const [dismissAlert, setDismissAlert] = useState(false);

  const closeAlert = () => {
    setDismissAlert(true);
  };

  switch (messageType) {
    case ACCOUNT_ACTIVATION_MESSAGE.SUCCESS: {
      heading = intl.formatMessage(messages['account.activation.success.message.title']);
      activationMessage = (
        <FormattedMessage
          id="account.activation.success.message"
          tagName="span"
          defaultMessage="You will now receive email updates and alerts from us related to the courses you are enrolled in. {signIn} to continue."
          values={{
            signIn: <span className="text-semi-bold">{intl.formatMessage(messages['sign.in.heading'])}</span>,
          }}
        />
      );
      break;
    }
    case ACCOUNT_ACTIVATION_MESSAGE.INFO: {
      activationMessage = intl.formatMessage(messages['account.already.activated.message']);
      break;
    }
    case ACCOUNT_ACTIVATION_MESSAGE.ERROR: {
      const supportLink = (
        <Alert.Link href={`mailto:${getConfig().SUPPORT_EMAIL}`}>
          {`${intl.formatMessage(messages['account.activation.support.link'])} ${getConfig().SUPPORT_EMAIL}`}
        </Alert.Link>
      );

      heading = intl.formatMessage(messages['account.activation.error.message.title']);
      activationMessage = (
        <FormattedMessage
          id="account.activation.error.message"
          defaultMessage="Something went wrong, please {supportLink} to resolve this issue."
          description="Account activation error message"
          values={{ supportLink }}
        />
      );
      break;
    }
    case ACCOUNT_ACTIVATION_MESSAGE.CONFIRM: {
      heading = intl.formatMessage(messages['account.activation.confirm.message.title']);
      activationMessage = (
        <FormattedMessage
          id="account.activation.confirm.message"
          defaultMessage={`Before you enroll in a course, {msgText}. We've sent an email message to
            your email address provided with link activation.`}
          values={{
            msgText: <span className="text-semi-bold">{intl.formatMessage(messages['account.activation.confirm.need.activate'])}</span>,
          }}
        />
      );
      activationMessageSecondary = intl.formatMessage(messages['account.activation.confirm.spam.message']);
      break;
    }
    default:
      break;
  }

  if (messageType === 'confirm' && activationMessage) {
    return (
      <AccountActivationConfirmAlert title={heading} messages={[activationMessage, activationMessageSecondary]} />
    );
  } return (activationMessage && (dismissAlert === false)) ? (
    <Alert id="account-activation-message" variant={variant === 'info' ? 'warning' : variant} align="left" dismissible onClick={closeAlert} fontWeight="normal">
      {heading && <Alert.Heading>{heading}</Alert.Heading>}
      {activationMessage}
    </Alert>
  ) : null;
};

AccountActivationMessage.propTypes = {
  messageType: PropTypes.string.isRequired,
  intl: intlShape.isRequired,
};

export default injectIntl(AccountActivationMessage);
