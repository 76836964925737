import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl, intlShape } from '@edx/frontend-platform/i18n';

import { Text } from 'navoica-frontend-ui';
import messages from '../../../common-components/messages';

import MailSent from '../../../assets/images/mail-sent.svg';

import {
  ConfirmationModal,
  ConfirmationContainer,
} from './styled';

const ConfirmationForgotPasswordModal = (props) => {
  const {
    intl, isOpen, close,
  } = props;

  return (
    <>
      <ConfirmationModal
        isOpen={isOpen}
        title={intl.formatMessage(messages['forgot.password.confirmation.title'])}
        onClose={close}
        size="lg"
        hasCloseButton
      >
        <ConfirmationContainer>
          <img src={MailSent} alt="" width="58" height="52" className="mb-4" />
          <Text color="#212529" size="lg" className="mb-4">{intl.formatMessage(messages['forgot.password.confirmation.info'])}</Text>
        </ConfirmationContainer>
      </ConfirmationModal>
    </>
  );
};

ConfirmationForgotPasswordModal.propTypes = {
  intl: intlShape.isRequired,
  isOpen: PropTypes.bool,
  close: PropTypes.func,
};

export default injectIntl(ConfirmationForgotPasswordModal);
