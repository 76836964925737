import { defineMessages } from '@edx/frontend-platform/i18n';

const messages = defineMessages({
  'register.page.title': {
    id: 'register.page.title',
    defaultMessage: 'Register for Navoica.pl | {siteName}',
    description: 'register page title',
  },
  'register.page.description': {
    id: 'register.page.description',
    defaultMessage: 'Polish MOOC platform offering free online courses for every registered user.',
    description: 'register page description',
  },
  'create.account.button': {
    id: 'create.account.button',
    defaultMessage: 'Register',
    description: 'Button label that appears on register page',
  },
  'already.have.an.edx.account': {
    id: 'already.have.an.edx.account',
    defaultMessage: 'Already have an edX account?',
    description: 'A message on registration page asking the user if he already has an edX account',
  },
  'sign.in.hyperlink': {
    id: 'sign.in.hyperlink',
    defaultMessage: 'Sign in.',
    description: 'Text for the hyperlink that takes user to login page',
  },
  'create.an.account.using': {
    id: 'create.an.account.using',
    defaultMessage: 'or create an account using',
    description: 'A message that after optional form fields checkbox',
  },
  'create.a.new.account': {
    id: 'create.a.new.account',
    defaultMessage: 'Register',
    description: 'Text that appears before social auth buttons and before the registration form',
  },
  'register.institution.login.button': {
    id: 'register.institution.login.button',
    defaultMessage: 'Use my institution/campus credentials',
    description: 'shows institutions list',
  },
  'register.institution.login.page.title': {
    id: 'register.institution.login.page.title',
    defaultMessage: 'Register with institution/campus credentials',
    description: 'Heading of institution page',
  },
  'create.an.account': {
    id: 'create.an.account',
    defaultMessage: 'Create an account',
    description: 'Message on button to return to register page',
  },
  'register.page.email.label': {
    id: 'register.page.email.label',
    defaultMessage: 'E-mail',
    description: 'Label that appears above email field on register page',
  },
  'register.page.email.confirm.label': {
    id: 'register.page.email.confirm.label',
    defaultMessage: 'Confirm E-mail',
    description: 'Label that appears above confirm email field on register page',
  },
  'register.rate.limit.reached.message': {
    id: 'register.rate.limit.reached.message',
    defaultMessage: 'Too many failed registration attempts. Try again later.',
    description: 'Error message that appears when an anonymous user has made too many failed registration attempts',
  },
  'email.validation.message': {
    id: 'email.validation.message',
    defaultMessage: 'Please enter your email.',
    description: 'Validation message that appears when email address is empty',
  },
  'email.ratelimit.less.chars.validation.message': {
    id: 'email.ratelimit.less.chars.validation.message',
    defaultMessage: 'Email must have 3 characters.',
    description: 'Validation message that appears when email address is less than 3 characters',
  },
  'email.ratelimit.incorrect.format.validation.message': {
    id: 'email.ratelimit.incorrect.format.validation.message',
    defaultMessage: 'A properly formatted e-mail is required.',
    description: 'Validation message that appears when email address is not formatted correctly with no backend validations available.',
  },
  'email.ratelimit.password.validation.message': {
    id: 'email.ratelimit.password.validation.message',
    defaultMessage: 'Your password must contain at least 8 characters',
    description: 'Validation message that appears when password is not formatted correctly with no backend validations available.',
  },
  'email.confirm.validation.message': {
    id: 'email.confirm.validation.message',
    defaultMessage: 'Emails don\'t match',
    description: 'Validation message that appears when confirm email is not the same as email.',
  },
  'email.confirm.empty.validation.message': {
    id: 'email.confirm.empty.validation.message',
    defaultMessage: 'A valid confirm email is required.',
    description: 'Validation message that appears when confirm email is empty.',
  },
  'password.label': {
    id: 'password.label',
    defaultMessage: 'Password',
    description: 'Label that appears above password field',
  },
  'password.confirm.label': {
    id: 'password.confirm.label',
    defaultMessage: 'Confirm Password',
    description: 'Label that appears above confirm password field',
  },
  'password.confirm.validation.message': {
    id: 'password.confirm.validation.message',
    defaultMessage: 'Passwords don\'t match',
    description: 'Validation message that appears when confirm password is not the same as password.',
  },
  'password.confirm.empty.validation.message': {
    id: 'password.confirm.empty.validation.message',
    defaultMessage: 'A valid confirm password is required.',
    description: 'Validation message that appears when confirm password is empty.',
  },
  'register.page.password.validation.message': {
    id: 'register.page.password.validation.message',
    defaultMessage: 'Please enter your password.',
    description: 'Validation message that appears when password is non compliant with edX requirement',
  },
  'password.validation.message': {
    id: 'password.validation.message',
    defaultMessage: 'A valid password is required.',
    description: 'Validation message that appears when password format is not correct',
  },
  'fullname.label': {
    id: 'fullname.label',
    defaultMessage: 'Full Name',
    description: 'Label that appears above fullname field',
  },
  'fullname.validation.message': {
    id: 'fullname.validation.message',
    defaultMessage: 'Please enter your full name.',
    description: 'Validation message that appears when fullname is empty',
  },
  'fullname.minlimit.validation.message': {
    id: 'fullname.minlimit.validation.message',
    defaultMessage: 'Your legal name must be a minimum of two characters long.',
    description: 'Validation message that appears when fullname is empty',
  },
  'username.label': {
    id: 'username.label',
    defaultMessage: 'Public Username',
    description: 'Label that appears above username field',
  },
  'username.validation.message': {
    id: 'username.validation.message',
    defaultMessage: 'Please enter your public username.',
    description: 'Validation message that appears when username is invalid',
  },
  'username.format.validation.message': {
    id: 'username.format.validation.message',
    defaultMessage: 'Usernames can only contain letters (A-Z, a-z), numerals (0-9), underscores (_), and hyphens (-) and must be between 2 and 255 characters long.',
    description: 'Validation message that appears when username format is invalid',
  },
  'username.character.validation.message': {
    id: 'username.character.validation.message',
    defaultMessage: 'Your password must contain at least 1 letter.',
    description: 'Validation message that appears when password does not contain letter',
  },
  'username.number.validation.message': {
    id: 'username.number.validation.message',
    defaultMessage: 'Your password must contain at least 1 number.',
    description: 'Validation message that appears when password does not contain number',
  },
  'username.ratelimit.less.chars.message': {
    id: 'username.ratelimit.less.chars.message',
    defaultMessage: 'Public username must be between 2 and 255 characters long.',
    description: 'Validation message that appears when username is less than 2 characters and with no backend validations available.',
  },
  'country.validation.message': {
    id: 'country.validation.message',
    defaultMessage: 'Select your country or region of residence.',
    description: 'Validation message that appears when country is not selected',
  },
  'terms.service.validation.message': {
    id: 'terms.service.validation.message',
    defaultMessage: 'You must accept the terms of service.',
    description: 'Validation message that appears when terms of service is not selected',
  },
  'honor.code.validation.message': {
    id: 'honor.code.validation.message',
    defaultMessage: 'To enroll, you must follow the honor code.',
    description: 'Validation message that appears when honor code is not selected',
  },
  'support.education.research': {
    id: 'support.education.research',
    defaultMessage: 'Support education research by providing additional information.',
    description: 'Text for a checkbox to ask user for if they are willing to provide extra information for education research',
  },
  'registration.request.server.error': {
    id: 'registration.request.server.error',
    defaultMessage: 'An error has occurred. Try refreshing the page, or check your internet connection.',
    description: 'error message on server error.',
  },
  'registration.request.failure.header': {
    id: 'registration.request.failure.header',
    defaultMessage: 'The form contains errors.',
    description: 'error message when registration failure.',
  },
  'registration.request.failure.description': {
    id: 'registration.request.failure.description',
    defaultMessage: 'Please correct or fill in the missing information.',
    description: 'error message when registration failure.',
  },
  'helptext.name': {
    id: 'helptext.name',
    defaultMessage: 'Full name is be displayed on certificates you obtain',
    description: 'help text for name field on registration field',
  },
  'helptext.username': {
    id: 'helptext.username',
    defaultMessage: 'User profile is visible in discussions and forums you participate in',
    description: 'helptext for username field on registration page',
  },
  'helptext.password': {
    id: 'helptext.password',
    defaultMessage: 'Use at least 8 characters',
    description: 'help text for password field on registration page',
  },
  'helptext.email': {
    id: 'helptext.email',
    defaultMessage: 'This is what you will use to login.',
    description: 'help text for email field on registration page',
  },
  // Terms of Service and Honor Code
  'honor.code': {
    id: 'honor.code',
    defaultMessage: 'Honor Code',
    description: 'Text for the hyperlink that redirects user to honor code',
  },
  'privacy.policy': {
    id: 'privacy.policy',
    defaultMessage: 'Privacy Policy',
    description: 'Text for the hyperlink that redirects user to privacy policy',
  },
  'terms.service': {
    id: 'terms.service',
    defaultMessage: 'Terms of Service',
    description: 'Text for the hyperlink that redirects user to terms of service',
  },
  // Registration Fields
  'registration.year.of.birth.label': {
    id: 'registration.year.of.birth.label',
    defaultMessage: 'Year of Birth',
    description: 'Placeholder for the year of birth options dropdown',
  },
  'registration.country.label': {
    id: 'registration.country.label',
    defaultMessage: 'Country',
    description: 'Placeholder for the country options dropdown.',
  },
  'registration.field.gender.label': {
    id: 'registration.field.gender.label',
    defaultMessage: 'Gender',
    description: 'Label for the gender options dropdown',
  },
  'registration.field.gender.options.label': {
    id: 'registration.field.gender.options.label',
    defaultMessage: 'Select...',
    description: 'Placeholder for the gender options dropdown',
  },
  'registration.goals.label': {
    id: 'registration.goals.label',
    defaultMessage: 'Tell us why you\'re interested in edX (optional)',
    description: 'Placeholder for the goals options dropdown',
  },
  'registration.field.gender.options.f': {
    id: 'registration.field.gender.options.f',
    defaultMessage: 'Female',
    description: 'The label for the female gender option.',
  },
  'registration.field.gender.options.m': {
    id: 'registration.field.gender.options.m',
    defaultMessage: 'Male',
    description: 'The label for the male gender option.',
  },
  'registration.field.gender.options.o': {
    id: 'registration.field.gender.options.o',
    defaultMessage: 'Other/Prefer not to say',
    description: 'The label for catch-all gender option.',
  },
  'registration.field.education.label': {
    id: 'registration.field.education.label',
    defaultMessage: 'Education',
    description: 'Label for the education levels dropdown.',
  },
  'registration.field.education.levels.label': {
    id: 'registration.field.education.levels.label',
    defaultMessage: 'Select...',
    description: 'Placeholder for the education levels dropdown.',
  },
  'registration.field.education.levels.p': {
    id: 'registration.field.education.levels.p',
    defaultMessage: 'Doctorate',
    description: 'Selected by the user if their highest level of education is a doctorate degree.',
  },
  'registration.field.education.levels.m': {
    id: 'registration.field.education.levels.m',
    defaultMessage: "Master's or professional degree",
    description: "Selected by the user if their highest level of education is a master's or professional degree from a college or university.",
  },
  'registration.field.education.levels.b': {
    id: 'registration.field.education.levels.b',
    defaultMessage: "Bachelor's degree",
    description: "Selected by the user if their highest level of education is a four year college or university bachelor's degree.",
  },
  'registration.field.education.levels.a': {
    id: 'registration.field.education.levels.a',
    defaultMessage: "Associate's degree",
    description: "Selected by the user if their highest level of education is an associate's degree. 1-2 years of college or university.",
  },
  'registration.field.education.levels.hs': {
    id: 'registration.field.education.levels.hs',
    defaultMessage: 'Secondary/high school',
    description: 'Selected by the user if their highest level of education is secondary or high school.  9-12 years of education.',
  },
  'registration.field.education.levels.jhs': {
    id: 'registration.field.education.levels.jhs',
    defaultMessage: 'Junior secondary/junior high/middle school',
    description: 'Selected by the user if their highest level of education is junior or middle school. 6-8 years of education.',
  },
  'registration.field.education.levels.el': {
    id: 'registration.field.education.levels.el',
    defaultMessage: 'Elementary/primary school',
    description: 'Selected by the user if their highest level of education is elementary or primary school.  1-5 years of education.',
  },
  'registration.field.education.levels.none': {
    id: 'registration.field.education.levels.none',
    defaultMessage: 'No formal education',
    description: 'Selected by the user to describe their education.',
  },
  'registration.field.education.levels.other': {
    id: 'registration.field.education.levels.other',
    defaultMessage: 'Other education',
    description: 'Selected by the user if they have a type of education not described by the other choices.',
  },
  'you.will.get.access.to.free.courses': {
    id: 'you.will.get.access.to.free.courses',
    defaultMessage: 'You will get access to free courses on Navoica.pl.',
    description: 'Text that appears after sign up title',
  },
  'required.fields': {
    id: 'required.fields',
    defaultMessage: 'Required fields are noted by an asterisk.',
    description: 'Text that appears after sign up title',
  },
  'required.field': {
    id: 'required.field',
    defaultMessage: 'required',
    description: 'Screen reader required field',
  },
  'cant.register': {
    id: 'cant.register',
    defaultMessage: 'You can\'t register?',
    description: 'Text help after register button',
  },
  'agree.honor_code': {
    id: 'agree.honor_code',
    defaultMessage: 'I agree to the',
    description: 'Agree checkbox honor code',
  },
  'agree.terms_of_service': {
    id: 'agree.terms_of_service',
    defaultMessage: 'I agree to the',
    description: 'Agree checkbox terms of service',
  },
  'select.placeholder': {
    id: 'select.placeholder',
    defaultMessage: 'Select...',
    description: 'Placeholder for select field',
  },
  'email.exist.validation.message': {
    id: 'email.exist.validation.message',
    defaultMessage: 'It looks like {email} belongs to an existing account. Try again with a different email address.',
    description: 'Error message on exist email in register form validation',
  },
  'username.exist.validation.message': {
    id: 'username.exist.validation.message',
    defaultMessage: 'It looks like {username} belongs to an existing account. Try again with a different username.',
    description: 'Error message on exist username in register form validation',
  },
  'show.password.message': {
    id: 'show.password.message',
    defaultMessage: 'Show password',
    description: 'Text info on icon show password in password field.',
  },
  'hide.password.message': {
    id: 'hide.password.message',
    defaultMessage: 'Hide password',
    description: 'Text info on icon hide password in password field.',
  },
  'password.visible.message': {
    id: 'password.visible.message',
    defaultMessage: 'Password visible. Your password is',
    description: 'Screen reader message for password visible.',
  },
  'password.hidden.message': {
    id: 'password.hidden.message',
    defaultMessage: 'Password hidden',
    description: 'Screen reader message for password hidden.',
  },
  'empty.value': {
    id: 'empty.value',
    defaultMessage: 'empty',
    description: 'Value of field is empty.',
  },
});

export default messages;
