import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl, intlShape } from '@edx/frontend-platform/i18n';
import { Form } from 'navoica-frontend-ui';
import ForgotPasswordModal from '../ForgotPasswordModal';

import messages from '../../../login/messages';

import {
  Container,
} from './styled';

const ForgotPasswordContainer = ({ intl, isConfirmedSendEmail, email }) => (
  <Container>
    <Form.Checkbox
      name="remember"
      isRequired={false}
      aria-required="false"
      aria-label={intl.formatMessage(messages['remember.me'])}
    >
      {intl.formatMessage(messages['remember.me'])}
    </Form.Checkbox>
    <ForgotPasswordModal
      isConfirmedSendEmail={isConfirmedSendEmail}
      email={email}
    />
  </Container>
);

ForgotPasswordContainer.propTypes = {
  intl: intlShape.isRequired,
  isConfirmedSendEmail: PropTypes.bool,
  email: PropTypes.string,
};

export default injectIntl(ForgotPasswordContainer);
